import Vue from 'vue';
import VueI18nManager from 'vue-i18n-manager';
import store from '@/store';
import router from '@/router';
import { translation } from '@/helpers/translation'


const proxy = {
  getTranslation: async () => {
    const response = await store.dispatch('setting/GET_TRANSLATIONS');
    Vue.use(response, translation);
    return response;
  }
};


Vue.use(VueI18nManager, {
  store,
  router,
  proxy,
  config: {
    defaultCode: 'en-EN',
    languages: [
      {
        name: 'English',
        title: 'En.',
        key: 'en',
        code: 'en-EN',
        urlPrefix: 'en',
        translationKey: 'en'
      },
      {
        name: 'Ukranian',
        title: 'Ua.',
        key: 'ua',
        code: 'ua-UA',
        urlPrefix: 'ua',
        translationKey: 'ua'
      },
      {
        name: 'Polish',
        title: 'Pl.',
        key: 'pl',
        code: 'pl-PL',
        urlPrefix: 'pl',
        translationKey: 'pl'
      },
    ],
    translations: proxy,
  },
});
