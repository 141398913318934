<template>
  <div id="app" class="app">
    <div class="app-container">
      <div class="wrapper">
        <div class="wrapper-top">
          <main-header/>
          <transition name="component-fade" mode="out-in">
            <router-view/>
          </transition>
          <div class="anchor-container">
            <a @click="scrollTo('contacts')" class="anchor-application">
              <svg width="94" height="94" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="47" cy="47" r="46.5" stroke="#FB9900"/>
              </svg>
              <div class="anchor-application-content">
                <span>{{$t('leaveApplication')}}</span>
              </div>
            </a>
            <a class="anchor-phone ad_saver_phone" :href="`tel:${$t('contactsPhone')}`">
              <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="26" cy="26" r="25.5" stroke="#FB9900"/>
                <path d="M38.1691 39.6667C34.7024 39.6667 31.4415 39.0111 28.3864 37.7C25.3304 36.3889 22.6637 34.5947 20.3864 32.3173C18.1082 30.0391 16.3082 27.3724 14.9864 24.3173C13.6637 21.2613 13.0024 17.9889 13.0024 14.5C12.9802 14.1 13.1135 13.7498 13.4024 13.4493C13.6913 13.1498 14.0468 13 14.469 13H19.669C20.069 13 20.4135 13.1498 20.7024 13.4493C20.9913 13.7498 21.1357 14.1111 21.1357 14.5333C21.1357 15.5111 21.2024 16.4333 21.3357 17.3C21.469 18.1667 21.6802 18.9889 21.969 19.7667C22.0802 20.0111 22.1024 20.2609 22.0357 20.516C21.969 20.772 21.8357 21.0111 21.6357 21.2333L18.3024 24.5667C19.2579 26.5222 20.6135 28.3778 22.369 30.1333C24.1246 31.8889 26.0135 33.2889 28.0357 34.3333L31.369 31C31.569 30.8 31.8024 30.6667 32.069 30.6C32.3357 30.5333 32.6024 30.5556 32.869 30.6667C33.6913 30.9333 34.541 31.1387 35.4184 31.2827C36.2966 31.4276 37.2024 31.5 38.1357 31.5C38.5579 31.5 38.9193 31.6556 39.2197 31.9667C39.5193 32.2778 39.669 32.6556 39.669 33.1V38.2C39.669 38.6 39.5246 38.9444 39.2357 39.2333C38.9468 39.5222 38.5913 39.6667 38.1691 39.6667Z" fill="#FB9900"/>
                <path d="M33.9026 18.7676C35.9693 20.8343 37.0026 23.3565 37.0026 26.3343H39.6693C39.6693 24.4899 39.3191 22.7565 38.6186 21.1343C37.9191 19.5121 36.9693 18.101 35.7693 16.901C34.5693 15.701 33.1582 14.7508 31.5359 14.0503C29.9137 13.3508 28.1804 13.001 26.3359 13.001V15.6676C29.3137 15.6676 31.8359 16.701 33.9026 18.7676Z" fill="white"/>
                <path d="M30.1026 22.5676C31.147 23.6121 31.6693 24.8676 31.6693 26.3343H34.3359C34.3359 24.1121 33.5582 22.2232 32.0026 20.6676C30.447 19.1121 28.5582 18.3343 26.3359 18.3343V21.001C27.8026 21.001 29.0582 21.5232 30.1026 22.5676Z" fill="white"/>
              </svg>
            </a>
          </div>
        </div>
<!--        <div class="wrapper-bottom">-->
<!--          <main-footer/>-->
<!--        </div>-->
      </div>
    </div>
    <hidden/>
    <transition name="component-fade" mode="out-in">
      <div class="global-loader" v-show="load">
        <div class="global-loader-img">
          <svg width="60" height="46" viewBox="0 0 60 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M31.9535 1.88314V1.43256V1H24.9767H18V15.4186V29.8372H18.5912H19.1825V23.1145V16.3919H24.5274H29.1155V15.9593V15.5087H24.5274H19.1825V8.69593V1.88314H25.568H31.9535Z" fill="white" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M41.5412 6.98883C41.5412 7.32549 39.8958 12.4817 34.305 29.5626L34.118 30.1651H33.314C33.688 28.9956 40.6437 7.62671 40.6811 7.46724L40.7372 7.25461H35.9317H31.145V6.91796V6.5813H36.3431H41.5412V6.98883Z" fill="white" stroke="white" stroke-width="2" stroke-miterlimit="10"/>
            <path d="M27.3023 30.3023V27.5116V25.186H26.8372H26.3721V27.5116V30.3023H26.8372H27.3023Z" fill="white" stroke="white" stroke-miterlimit="10"/>
            <mask id="path-4-outside-1_142_53" maskUnits="userSpaceOnUse" x="0" y="33" width="60" height="13" fill="black">
              <rect fill="white" y="33" width="60" height="13"/>
              <path d="M1.3054 43.8363H1V34.1385H4.53982V34.4295H1.3054V38.7519H3.99844V39.0428H1.3054V43.8363Z"/>
              <path d="M9.49383 43.8363H9.18843L8.64705 41.4811H5.5098L4.9823 43.8363H4.69078L6.85632 34.097H7.27277L9.49383 43.8363ZM7.06454 34.4987L5.57921 41.1902H8.59152L7.06454 34.4987Z"/>
              <path d="M10.9846 43.8363H10.6792V34.1385H12.581C13.3121 34.1385 13.8396 34.3094 14.1635 34.6511C14.4874 34.9929 14.6493 35.547 14.6493 36.3136V36.9093C14.6493 38.073 14.1774 38.7427 13.2334 38.9181V38.932C13.7239 39.0059 14.0802 39.1998 14.3023 39.5139C14.5337 39.8186 14.6493 40.2758 14.6493 40.8854V42.6864C14.6493 43.2036 14.6956 43.5869 14.7882 43.8363H14.4689C14.3856 43.5315 14.3439 43.1482 14.3439 42.6864V40.8715C14.3439 40.2343 14.2005 39.7771 13.9136 39.5C13.6267 39.2229 13.1501 39.0844 12.4838 39.0844H10.9846V43.8363ZM10.9846 34.4295V38.7935H12.4283C13.0946 38.7935 13.5805 38.6457 13.8859 38.3501C14.1912 38.0546 14.3439 37.5835 14.3439 36.937V36.3413C14.3439 35.6579 14.2051 35.1683 13.9275 34.8728C13.6591 34.5772 13.2057 34.4295 12.5671 34.4295H10.9846Z"/>
              <path d="M18.2243 43.8363H16.1559V34.1385H18.0855C18.8166 34.1385 19.3441 34.3094 19.668 34.6511C19.9919 34.9929 20.1538 35.547 20.1538 36.3136V36.7431C20.1538 37.8976 19.6865 38.5672 18.7518 38.7519V38.7657C19.2978 38.8489 19.6911 39.0567 19.9317 39.3892C20.1816 39.7217 20.3065 40.2065 20.3065 40.8438V41.6474C20.3065 42.4047 20.14 42.9589 19.8068 43.3098C19.4736 43.6608 18.9461 43.8363 18.2243 43.8363ZM16.4613 34.4295V38.6411H17.9328C18.5991 38.6411 19.085 38.4933 19.3904 38.1977C19.6958 37.9022 19.8485 37.4312 19.8485 36.7846V36.3413C19.8485 35.6579 19.7096 35.1683 19.432 34.8728C19.1636 34.5772 18.7102 34.4295 18.0716 34.4295H16.4613ZM16.4613 38.9181V43.5453H18.2243C18.8536 43.5453 19.3071 43.3976 19.5847 43.102C19.8623 42.7972 20.0011 42.3123 20.0011 41.6474V40.83C20.0011 40.1558 19.8438 39.6709 19.5292 39.3753C19.2145 39.0705 18.687 38.9181 17.9467 38.9181H16.4613Z"/>
              <path d="M21.9787 43.8363H21.6733V34.1385H21.9787V43.8363Z"/>
              <path d="M27.9575 43.8363H27.6521L27.1107 41.4811H23.9735L23.446 43.8363H23.1545L25.32 34.097H25.7364L27.9575 43.8363ZM25.5282 34.4987L24.0429 41.1902H27.0552L25.5282 34.4987Z"/>
              <path d="M29.4483 43.8363H29.1429V34.1385H31.0447C31.7758 34.1385 32.3033 34.3094 32.6272 34.6511C32.9511 34.9929 33.113 35.547 33.113 36.3136V36.9093C33.113 38.073 32.641 38.7427 31.6971 38.9181V38.932C32.1876 39.0059 32.5439 39.1998 32.766 39.5139C32.9973 39.8186 33.113 40.2758 33.113 40.8854V42.6864C33.113 43.2036 33.1593 43.5869 33.2518 43.8363H32.9326C32.8493 43.5315 32.8076 43.1482 32.8076 42.6864V40.8715C32.8076 40.2343 32.6642 39.7771 32.3773 39.5C32.0904 39.2229 31.6138 39.0844 30.9475 39.0844H29.4483V43.8363ZM29.4483 34.4295V38.7935H30.892C31.5583 38.7935 32.0441 38.6457 32.3495 38.3501C32.6549 38.0546 32.8076 37.5835 32.8076 36.937V36.3413C32.8076 35.6579 32.6688 35.1683 32.3912 34.8728C32.1228 34.5772 31.6693 34.4295 31.0308 34.4295H29.4483Z"/>
              <path d="M34.3281 41.7859V41.2733H34.6196V41.7997C34.6196 42.4001 34.7723 42.8665 35.0777 43.199C35.3831 43.5222 35.8088 43.6839 36.3548 43.6839C36.9008 43.6839 37.3265 43.5222 37.6319 43.199C37.9373 42.8665 38.09 42.4001 38.09 41.7997C38.09 41.2918 37.9558 40.8346 37.6875 40.4282C37.4191 40.0218 36.9471 39.5647 36.2715 39.0567C35.5497 38.5118 35.0407 38.0222 34.7445 37.5882C34.4669 37.1633 34.3281 36.683 34.3281 36.1474C34.3281 35.4824 34.5039 34.9605 34.8556 34.5819C35.2165 34.194 35.7116 34 36.3409 34C36.9702 34 37.4607 34.194 37.8124 34.5819C38.164 34.9605 38.3399 35.4962 38.3399 36.1889V36.5491H38.0345V36.1751C38.0345 35.5747 37.8864 35.1129 37.5903 34.7897C37.2941 34.4572 36.8777 34.2909 36.3409 34.2909C35.8042 34.2909 35.3831 34.4572 35.0777 34.7897C34.7816 35.1129 34.6335 35.5609 34.6335 36.1335C34.6335 36.6045 34.7677 37.0432 35.0361 37.4496C35.2952 37.8283 35.7764 38.2855 36.4797 38.8212C37.2201 39.3753 37.7198 39.8648 37.979 40.2897C38.2566 40.7145 38.3954 41.2133 38.3954 41.7859C38.3954 42.4786 38.2149 43.0189 37.854 43.4068C37.4931 43.7855 36.9934 43.9748 36.3548 43.9748C35.7163 43.9748 35.2165 43.7855 34.8556 43.4068C34.5039 43.0189 34.3281 42.4786 34.3281 41.7859Z"/>
              <path d="M44.0482 43.8363H43.715L41.4523 38.1562L40.0086 40.3728V43.8363H39.7032V34.1385H40.0086V39.8602L43.6734 34.1385H44.0204L41.6466 37.8375L44.0482 43.8363Z"/>
              <path d="M49.5933 43.8363H49.2879L48.7466 41.4811H45.6093L45.0818 43.8363H44.7903L46.9558 34.097H47.3723L49.5933 43.8363ZM47.1641 34.4987L45.6787 41.1902H48.691L47.1641 34.4987Z"/>
              <path d="M50.9559 45H50.8032L51.0947 43.8363H50.9976V42.7003H51.303V43.8224L50.9559 45Z"/>
              <path d="M55.8627 43.8363H55.5296L58.6946 34.4295H54.8077V34.1385H59V34.4295L55.8627 43.8363Z"/>
            </mask>
            <path d="M1.3054 43.8363H1V34.1385H4.53982V34.4295H1.3054V38.7519H3.99844V39.0428H1.3054V43.8363Z" fill="white"/>
            <path d="M9.49383 43.8363H9.18843L8.64705 41.4811H5.5098L4.9823 43.8363H4.69078L6.85632 34.097H7.27277L9.49383 43.8363ZM7.06454 34.4987L5.57921 41.1902H8.59152L7.06454 34.4987Z" fill="white"/>
            <path d="M10.9846 43.8363H10.6792V34.1385H12.581C13.3121 34.1385 13.8396 34.3094 14.1635 34.6511C14.4874 34.9929 14.6493 35.547 14.6493 36.3136V36.9093C14.6493 38.073 14.1774 38.7427 13.2334 38.9181V38.932C13.7239 39.0059 14.0802 39.1998 14.3023 39.5139C14.5337 39.8186 14.6493 40.2758 14.6493 40.8854V42.6864C14.6493 43.2036 14.6956 43.5869 14.7882 43.8363H14.4689C14.3856 43.5315 14.3439 43.1482 14.3439 42.6864V40.8715C14.3439 40.2343 14.2005 39.7771 13.9136 39.5C13.6267 39.2229 13.1501 39.0844 12.4838 39.0844H10.9846V43.8363ZM10.9846 34.4295V38.7935H12.4283C13.0946 38.7935 13.5805 38.6457 13.8859 38.3501C14.1912 38.0546 14.3439 37.5835 14.3439 36.937V36.3413C14.3439 35.6579 14.2051 35.1683 13.9275 34.8728C13.6591 34.5772 13.2057 34.4295 12.5671 34.4295H10.9846Z" fill="white"/>
            <path d="M18.2243 43.8363H16.1559V34.1385H18.0855C18.8166 34.1385 19.3441 34.3094 19.668 34.6511C19.9919 34.9929 20.1538 35.547 20.1538 36.3136V36.7431C20.1538 37.8976 19.6865 38.5672 18.7518 38.7519V38.7657C19.2978 38.8489 19.6911 39.0567 19.9317 39.3892C20.1816 39.7217 20.3065 40.2065 20.3065 40.8438V41.6474C20.3065 42.4047 20.14 42.9589 19.8068 43.3098C19.4736 43.6608 18.9461 43.8363 18.2243 43.8363ZM16.4613 34.4295V38.6411H17.9328C18.5991 38.6411 19.085 38.4933 19.3904 38.1977C19.6958 37.9022 19.8485 37.4312 19.8485 36.7846V36.3413C19.8485 35.6579 19.7096 35.1683 19.432 34.8728C19.1636 34.5772 18.7102 34.4295 18.0716 34.4295H16.4613ZM16.4613 38.9181V43.5453H18.2243C18.8536 43.5453 19.3071 43.3976 19.5847 43.102C19.8623 42.7972 20.0011 42.3123 20.0011 41.6474V40.83C20.0011 40.1558 19.8438 39.6709 19.5292 39.3753C19.2145 39.0705 18.687 38.9181 17.9467 38.9181H16.4613Z" fill="white"/>
            <path d="M21.9787 43.8363H21.6733V34.1385H21.9787V43.8363Z" fill="white"/>
            <path d="M27.9575 43.8363H27.6521L27.1107 41.4811H23.9735L23.446 43.8363H23.1545L25.32 34.097H25.7364L27.9575 43.8363ZM25.5282 34.4987L24.0429 41.1902H27.0552L25.5282 34.4987Z" fill="white"/>
            <path d="M29.4483 43.8363H29.1429V34.1385H31.0447C31.7758 34.1385 32.3033 34.3094 32.6272 34.6511C32.9511 34.9929 33.113 35.547 33.113 36.3136V36.9093C33.113 38.073 32.641 38.7427 31.6971 38.9181V38.932C32.1876 39.0059 32.5439 39.1998 32.766 39.5139C32.9973 39.8186 33.113 40.2758 33.113 40.8854V42.6864C33.113 43.2036 33.1593 43.5869 33.2518 43.8363H32.9326C32.8493 43.5315 32.8076 43.1482 32.8076 42.6864V40.8715C32.8076 40.2343 32.6642 39.7771 32.3773 39.5C32.0904 39.2229 31.6138 39.0844 30.9475 39.0844H29.4483V43.8363ZM29.4483 34.4295V38.7935H30.892C31.5583 38.7935 32.0441 38.6457 32.3495 38.3501C32.6549 38.0546 32.8076 37.5835 32.8076 36.937V36.3413C32.8076 35.6579 32.6688 35.1683 32.3912 34.8728C32.1228 34.5772 31.6693 34.4295 31.0308 34.4295H29.4483Z" fill="white"/>
            <path d="M34.3281 41.7859V41.2733H34.6196V41.7997C34.6196 42.4001 34.7723 42.8665 35.0777 43.199C35.3831 43.5222 35.8088 43.6839 36.3548 43.6839C36.9008 43.6839 37.3265 43.5222 37.6319 43.199C37.9373 42.8665 38.09 42.4001 38.09 41.7997C38.09 41.2918 37.9558 40.8346 37.6875 40.4282C37.4191 40.0218 36.9471 39.5647 36.2715 39.0567C35.5497 38.5118 35.0407 38.0222 34.7445 37.5882C34.4669 37.1633 34.3281 36.683 34.3281 36.1474C34.3281 35.4824 34.5039 34.9605 34.8556 34.5819C35.2165 34.194 35.7116 34 36.3409 34C36.9702 34 37.4607 34.194 37.8124 34.5819C38.164 34.9605 38.3399 35.4962 38.3399 36.1889V36.5491H38.0345V36.1751C38.0345 35.5747 37.8864 35.1129 37.5903 34.7897C37.2941 34.4572 36.8777 34.2909 36.3409 34.2909C35.8042 34.2909 35.3831 34.4572 35.0777 34.7897C34.7816 35.1129 34.6335 35.5609 34.6335 36.1335C34.6335 36.6045 34.7677 37.0432 35.0361 37.4496C35.2952 37.8283 35.7764 38.2855 36.4797 38.8212C37.2201 39.3753 37.7198 39.8648 37.979 40.2897C38.2566 40.7145 38.3954 41.2133 38.3954 41.7859C38.3954 42.4786 38.2149 43.0189 37.854 43.4068C37.4931 43.7855 36.9934 43.9748 36.3548 43.9748C35.7163 43.9748 35.2165 43.7855 34.8556 43.4068C34.5039 43.0189 34.3281 42.4786 34.3281 41.7859Z" fill="white"/>
            <path d="M44.0482 43.8363H43.715L41.4523 38.1562L40.0086 40.3728V43.8363H39.7032V34.1385H40.0086V39.8602L43.6734 34.1385H44.0204L41.6466 37.8375L44.0482 43.8363Z" fill="white"/>
            <path d="M49.5933 43.8363H49.2879L48.7466 41.4811H45.6093L45.0818 43.8363H44.7903L46.9558 34.097H47.3723L49.5933 43.8363ZM47.1641 34.4987L45.6787 41.1902H48.691L47.1641 34.4987Z" fill="white"/>
            <path d="M50.9559 45H50.8032L51.0947 43.8363H50.9976V42.7003H51.303V43.8224L50.9559 45Z" fill="white"/>
            <path d="M55.8627 43.8363H55.5296L58.6946 34.4295H54.8077V34.1385H59V34.4295L55.8627 43.8363Z" fill="white"/>
            <path d="M1.3054 43.8363H1V34.1385H4.53982V34.4295H1.3054V38.7519H3.99844V39.0428H1.3054V43.8363Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
            <path d="M9.49383 43.8363H9.18843L8.64705 41.4811H5.5098L4.9823 43.8363H4.69078L6.85632 34.097H7.27277L9.49383 43.8363ZM7.06454 34.4987L5.57921 41.1902H8.59152L7.06454 34.4987Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
            <path d="M10.9846 43.8363H10.6792V34.1385H12.581C13.3121 34.1385 13.8396 34.3094 14.1635 34.6511C14.4874 34.9929 14.6493 35.547 14.6493 36.3136V36.9093C14.6493 38.073 14.1774 38.7427 13.2334 38.9181V38.932C13.7239 39.0059 14.0802 39.1998 14.3023 39.5139C14.5337 39.8186 14.6493 40.2758 14.6493 40.8854V42.6864C14.6493 43.2036 14.6956 43.5869 14.7882 43.8363H14.4689C14.3856 43.5315 14.3439 43.1482 14.3439 42.6864V40.8715C14.3439 40.2343 14.2005 39.7771 13.9136 39.5C13.6267 39.2229 13.1501 39.0844 12.4838 39.0844H10.9846V43.8363ZM10.9846 34.4295V38.7935H12.4283C13.0946 38.7935 13.5805 38.6457 13.8859 38.3501C14.1912 38.0546 14.3439 37.5835 14.3439 36.937V36.3413C14.3439 35.6579 14.2051 35.1683 13.9275 34.8728C13.6591 34.5772 13.2057 34.4295 12.5671 34.4295H10.9846Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
            <path d="M18.2243 43.8363H16.1559V34.1385H18.0855C18.8166 34.1385 19.3441 34.3094 19.668 34.6511C19.9919 34.9929 20.1538 35.547 20.1538 36.3136V36.7431C20.1538 37.8976 19.6865 38.5672 18.7518 38.7519V38.7657C19.2978 38.8489 19.6911 39.0567 19.9317 39.3892C20.1816 39.7217 20.3065 40.2065 20.3065 40.8438V41.6474C20.3065 42.4047 20.14 42.9589 19.8068 43.3098C19.4736 43.6608 18.9461 43.8363 18.2243 43.8363ZM16.4613 34.4295V38.6411H17.9328C18.5991 38.6411 19.085 38.4933 19.3904 38.1977C19.6958 37.9022 19.8485 37.4312 19.8485 36.7846V36.3413C19.8485 35.6579 19.7096 35.1683 19.432 34.8728C19.1636 34.5772 18.7102 34.4295 18.0716 34.4295H16.4613ZM16.4613 38.9181V43.5453H18.2243C18.8536 43.5453 19.3071 43.3976 19.5847 43.102C19.8623 42.7972 20.0011 42.3123 20.0011 41.6474V40.83C20.0011 40.1558 19.8438 39.6709 19.5292 39.3753C19.2145 39.0705 18.687 38.9181 17.9467 38.9181H16.4613Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
            <path d="M21.9787 43.8363H21.6733V34.1385H21.9787V43.8363Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
            <path d="M27.9575 43.8363H27.6521L27.1107 41.4811H23.9735L23.446 43.8363H23.1545L25.32 34.097H25.7364L27.9575 43.8363ZM25.5282 34.4987L24.0429 41.1902H27.0552L25.5282 34.4987Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
            <path d="M29.4483 43.8363H29.1429V34.1385H31.0447C31.7758 34.1385 32.3033 34.3094 32.6272 34.6511C32.9511 34.9929 33.113 35.547 33.113 36.3136V36.9093C33.113 38.073 32.641 38.7427 31.6971 38.9181V38.932C32.1876 39.0059 32.5439 39.1998 32.766 39.5139C32.9973 39.8186 33.113 40.2758 33.113 40.8854V42.6864C33.113 43.2036 33.1593 43.5869 33.2518 43.8363H32.9326C32.8493 43.5315 32.8076 43.1482 32.8076 42.6864V40.8715C32.8076 40.2343 32.6642 39.7771 32.3773 39.5C32.0904 39.2229 31.6138 39.0844 30.9475 39.0844H29.4483V43.8363ZM29.4483 34.4295V38.7935H30.892C31.5583 38.7935 32.0441 38.6457 32.3495 38.3501C32.6549 38.0546 32.8076 37.5835 32.8076 36.937V36.3413C32.8076 35.6579 32.6688 35.1683 32.3912 34.8728C32.1228 34.5772 31.6693 34.4295 31.0308 34.4295H29.4483Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
            <path d="M34.3281 41.7859V41.2733H34.6196V41.7997C34.6196 42.4001 34.7723 42.8665 35.0777 43.199C35.3831 43.5222 35.8088 43.6839 36.3548 43.6839C36.9008 43.6839 37.3265 43.5222 37.6319 43.199C37.9373 42.8665 38.09 42.4001 38.09 41.7997C38.09 41.2918 37.9558 40.8346 37.6875 40.4282C37.4191 40.0218 36.9471 39.5647 36.2715 39.0567C35.5497 38.5118 35.0407 38.0222 34.7445 37.5882C34.4669 37.1633 34.3281 36.683 34.3281 36.1474C34.3281 35.4824 34.5039 34.9605 34.8556 34.5819C35.2165 34.194 35.7116 34 36.3409 34C36.9702 34 37.4607 34.194 37.8124 34.5819C38.164 34.9605 38.3399 35.4962 38.3399 36.1889V36.5491H38.0345V36.1751C38.0345 35.5747 37.8864 35.1129 37.5903 34.7897C37.2941 34.4572 36.8777 34.2909 36.3409 34.2909C35.8042 34.2909 35.3831 34.4572 35.0777 34.7897C34.7816 35.1129 34.6335 35.5609 34.6335 36.1335C34.6335 36.6045 34.7677 37.0432 35.0361 37.4496C35.2952 37.8283 35.7764 38.2855 36.4797 38.8212C37.2201 39.3753 37.7198 39.8648 37.979 40.2897C38.2566 40.7145 38.3954 41.2133 38.3954 41.7859C38.3954 42.4786 38.2149 43.0189 37.854 43.4068C37.4931 43.7855 36.9934 43.9748 36.3548 43.9748C35.7163 43.9748 35.2165 43.7855 34.8556 43.4068C34.5039 43.0189 34.3281 42.4786 34.3281 41.7859Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
            <path d="M44.0482 43.8363H43.715L41.4523 38.1562L40.0086 40.3728V43.8363H39.7032V34.1385H40.0086V39.8602L43.6734 34.1385H44.0204L41.6466 37.8375L44.0482 43.8363Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
            <path d="M49.5933 43.8363H49.2879L48.7466 41.4811H45.6093L45.0818 43.8363H44.7903L46.9558 34.097H47.3723L49.5933 43.8363ZM47.1641 34.4987L45.6787 41.1902H48.691L47.1641 34.4987Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
            <path d="M50.9559 45H50.8032L51.0947 43.8363H50.9976V42.7003H51.303V43.8224L50.9559 45Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
            <path d="M55.8627 43.8363H55.5296L58.6946 34.4295H54.8077V34.1385H59V34.4295L55.8627 43.8363Z" stroke="white" stroke-width="0.6" mask="url(#path-4-outside-1_142_53)"/>
          </svg>
        </div>
      </div>
    </transition>
    <transition name="component-fade" mode="out-in">
      <div class="cookie" v-if="!acceptCookie">
        <div class="container">
          <div class="cookie-wrap">
            <div class="cookie-title" v-html="getVariable('cookies')"/>
            <div class="cookie-btn-wrap">
              <main-button @change-event="setAccept(true)" :label="$t('btnAccept')"/>
              <a @click="setAccept(false)">
                <span>{{$t('btnDecline')}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style src="@/assets/scss/main.scss" lang="scss"></style>

<script>
import {mapGetters, mapActions, mapMutations} from 'vuex';
import Hidden from '@/components/hidden/index.vue';
import MainHeader from '@/components/header/index.vue';
import MainFooter from '@/components/footer/index.vue';
import {isMobile, isIPad} from '@/helpers/variables/index'
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
  name: 'app',
  components: {
    Hidden,
    MainHeader,
    MainFooter
  },
  data() {
    return {
      load: true,
      acceptCookie: false,
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: `auth/isAuthenticated`,
      variables: `setting/variables`,
      globalLoader: 'system/globalLoader',
    }),
  },
  created() {
    this.getVariables()
    localStorage.getItem('cookie') ? this.acceptCookie = true : this.acceptCookie = false
  },
  mounted() {
    if (!isMobile.any()) {
      document.querySelector('body').classList.add('hover');
    }
    if (!isIPad.iOS()) {
      document.querySelector('body').classList.add('iPad');
    }
    setTimeout(()=>{
      this.load = false
    },1500)
    setTimeout(()=>{
      AOS.init({
        disable: function () {
          const maxWidth = 1000;
          return window.innerWidth <= maxWidth;
        }
      });
    },100)
  },
  methods: {
    scrollTo(id) {
      this.$nextTick(() => {
        const yOffset = -100
        const el = document.querySelector(`#${id}`)
        const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({
          top: y,
          behavior: "smooth",
        })
      })
    },
    setAccept(type){
      this.acceptCookie = true
     if(type){
       localStorage.setItem('cookie', 'true')
     } else {
       localStorage.removeItem('cookie')
     }
    },
    getVariable(key) {
      return this.variables ? this.variables.find(el => el.key === key) ? this.variables.find(el => el.key === key).value : null : null
    },
    ...mapMutations({
    }),
    ...mapActions({
      getVariables: 'setting/GET_VARIABLES',
    }),
  }
}
</script>
