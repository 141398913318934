import {mapMutations, mapGetters} from "vuex";
import {directive as onClickaway} from 'vue-clickaway';
import changeHtmlLang from "@/utils/change-html-lang";

export default {
  name: "main-header",
  components: {},
  directives: {
    onClickaway: onClickaway,
  },
  data() {
    return {
      scroll: false,
      burger: false,
      showLangList: false,
      showLangListMobile: false,
    }
  },
  created() {
    if(this.$route.hash){
      setTimeout(()=>{
        this.scrollTo(this.$route.hash.slice(1))
      },300)
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
  },
  mounted() {
    window.addEventListener('scroll', () => {
      window.scrollY > 74 ? this.scroll = true : this.scroll = false
    })
    window.scrollY > 74 ? this.scroll = true : this.scroll = false

  },
  methods: {
    ...mapMutations({}),
    closeLangList() {
      this.showLangList = false;
    },
    closeLangListMobile() {
      this.showLangListMobile = false;
    },
    changeLanguage(language) {
      const selectedLanguage = this.languages.find(item => item.key === language.key);

      this.$setLanguage(selectedLanguage.code);
      localStorage.setItem('localization', selectedLanguage.key);
      changeHtmlLang(selectedLanguage.key);

      this.showLangList = false;

      setTimeout(() => {
        window.location.reload();
      }, 1000)
    },
    scrollTo(id) {
      this.burger = false
      if(this.$route.name !== 'home'){
        let url = this.$router.resolve({name: 'home', hash: `#${id}`})
        window.location.href = url.href
      } else {
        this.$nextTick(() => {
          const yOffset = -100
          const el = document.querySelector(`#${id}`)
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;
          this.$router.push({
              hash: `#${id}`
            }
          ).catch(() => {
            console.log()
          });
          setTimeout(()=>{
            window.scrollTo({
              top: y,
              behavior: "smooth",
            })
          },20)
        })
      }
    }
  }
}