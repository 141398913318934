import {$http} from '@/utils/https'
import {
  CHANGE_LOADING_LIST,
  SET_LIST,
  CHANGE_LOADING_FLAT,
  SET_FLAT,
  SET_MAP,
  CHANGE_LOADING_PROGRESS,
  SET_PROGRESS,
  SET_CONTACTS, GET_FILTERS_LOADING, SET_FILTERS, SET_LIST_LENGTH, CHANGE_LOADING_FEEDBACK,
  SET_CONSTRUCTION_PROGRESS, CHANGE_LOADING_CONSTRUCTION_PROGRESS, SET_CITY, CHANGE_LOADING_CITY,
  SET_SERVICES, CHANGE_LOADING_SERVICES, CHANGE_LOADING_TECH, SET_TECH, CHANGE_LOADING_SPEC, SET_SPEC,
  CHANGE_LOADING_TEAM, SET_TEAM,
} from '../mutation-types';
import {
  GET_CONTACTS, GET_FILTERS,
  GET_LIST, SEND_FEEDBACK, GET_FLAT, GET_MAP, GET_PROGRESS,
  GET_CONSTRUCTION_PROGRESS, GET_CITY, GET_SERVICES, GET_TECH, GET_SPEC, GET_TEAM,
} from '../action-types';


const state = {
  list: [],
  listLoading: false,
  flat: null,
  map: null,
  flatLoading: false,
  contacts: null,
  filters: [],
  filterLoading: false,
  feedbackLoading: false,
  progress: null,
  city: null,
  constructionProgress: null,
  cityLoading: false,
  progressLoading: false,
  constructionProgressLoading: false,
  services: null,
  servicesLoading: false,
  tech: null,
  techLoading: false,
  spec: null,
  specLoading: false,
  team: null,
  teamLoading: false,
};

const getters = {
  contacts: state => state.contacts,
  list: state => state.list,
  listLoading: state => state.listLoading,
  filters: state => state.filters,
  filterLoading: state => state.filterLoading,
  feedbackLoading: state => state.feedbackLoading,
  flat: state => state.flat,
  flatLoading: state => state.flatLoading,
  map: state => state.map,
  progress: state => state.progress,
  progressLoading: state => state.progressLoading,
  constructionProgress: state => state.constructionProgress,
  constructionProgressLoading: state => state.constructionProgressLoading,
  city: state => state.city,
  cityLoading: state => state.cityLoading,
  services: state => state.services,
  servicesLoading: state => state.servicesLoading,
  tech: state => state.tech,
  techLoading: state => state.techLoading,
  spec: state => state.spec,
  specLoading: state => state.specLoading,
  team: state => state.team,
  teamLoading: state => state.teamLoading,
};

const actions = {
  [GET_TECH]: async ({commit}) => {
    commit(CHANGE_LOADING_TECH, true);
    try {
      const response = await $http.get(`v1/uniquenesses`);
      commit(SET_TECH, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_TECH, false);
    }
  },
  [GET_TEAM]: async ({commit}) => {
    commit(CHANGE_LOADING_TEAM, true);
    try {
      const response = await $http.get(`v1/command_members_positions`);
      commit(SET_TEAM, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_TEAM, false);
    }
  },
  [GET_SPEC]: async ({commit}) => {
    commit(CHANGE_LOADING_SPEC, true);
    try {
      const response = await $http.get(`v1/specifications`);
      commit(SET_SPEC, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_SPEC, false);
    }
  },
  [GET_PROGRESS]: async ({commit}) => {
    commit(CHANGE_LOADING_PROGRESS, true);
    try {
      const response = await $http.get(`v1/progress_slider`);
      commit(SET_PROGRESS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_PROGRESS, false);
    }
  },
  [GET_CITY]: async ({commit}) => {
    commit(CHANGE_LOADING_CITY, true);
    try {
      const response = await $http.get(`v1/about_city`);
      commit(SET_CITY, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_CITY, false);
    }
  },
  [GET_SERVICES]: async ({commit}) => {
    commit(CHANGE_LOADING_SERVICES, true);
    try {
      const response = await $http.get(`v1/services`);
      commit(SET_SERVICES, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_SERVICES, false);
    }
  },
  [GET_CONSTRUCTION_PROGRESS]: async ({commit}) => {
    commit(CHANGE_LOADING_CONSTRUCTION_PROGRESS, true);
    try {
      const response = await $http.get(`v1/construction_progress`);
      commit(SET_CONSTRUCTION_PROGRESS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_CONSTRUCTION_PROGRESS, false);
    }
  },
  [SEND_FEEDBACK]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_FEEDBACK, true);
    try {
      return await $http.post(`v1/feedback`, payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_FEEDBACK, false);
    }
  },
  [GET_LIST]: async ({commit}) => {
    commit(CHANGE_LOADING_LIST, true);
    try {
      const response = await $http.get(`v1/product?limit=${4}&main_page=true`);
      commit(SET_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_LIST, false);
    }
  },
  [GET_MAP]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/variables/google_map`);
      commit(SET_MAP, response.data.data);
    } catch (e) {
      throw e;
    } finally {
    }
  },
  [GET_FLAT]: async ({commit}, id) => {
    commit(CHANGE_LOADING_FLAT, true);
    try {
      const response = await $http.get(`v1/product/${id}`);
      commit(SET_FLAT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_FLAT, false);
    }
  },
  [GET_CONTACTS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/contact`);
      commit(SET_CONTACTS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_FILTERS]: async ({commit}) => {
    try {
      commit(GET_FILTERS_LOADING, true);
      const response = await $http.get(`v1/category?main_page=true`);
      commit(SET_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(GET_FILTERS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_LIST](state, list) {
    state.list = list;
  },
  [SET_TECH](state, list) {
    state.tech = list;
  },
  [SET_TEAM](state, list) {
    state.team = list;
  },
  [SET_SPEC](state, list) {
    state.spec = list;
  },
  [SET_SERVICES](state, list) {
    state.services = list;
  },
  [CHANGE_LOADING_SERVICES](state, status) {
    state.servicesLoading = status;
  },
  [CHANGE_LOADING_TEAM](state, status) {
    state.teamLoading = status;
  },
  [CHANGE_LOADING_TECH](state, status) {
    state.techLoading = status;
  },
  [CHANGE_LOADING_SPEC](state, status) {
    state.specLoading = status;
  },
  [SET_PROGRESS](state, list) {
    state.progress = list;
  },
  [SET_CITY](state, list) {
    state.city = list;
  },
  [CHANGE_LOADING_PROGRESS](state, status) {
    state.progressLoading = status;
  },
  [CHANGE_LOADING_CITY](state, status) {
    state.cityLoading = status;
  },
  [SET_CONSTRUCTION_PROGRESS](state, list) {
    state.constructionProgress = list;
  },
  [CHANGE_LOADING_CONSTRUCTION_PROGRESS](state, status) {
    state.constructionProgressLoading = status;
  },
  [SET_FLAT](state, list) {
    state.flat = list;
  },
  [SET_MAP](state, list) {
    state.map = list;
  },
  [CHANGE_LOADING_FLAT](state, status) {
    state.flatLoading = status;
  },
  [SET_CONTACTS](state, data) {
    state.contacts = data;
  },
  [CHANGE_LOADING_LIST](state, status) {
    state.listLoading = status;
  },
  [SET_LIST_LENGTH](state, count) {
    state.count = count;
  },
  [SET_FILTERS](state, list) {
    state.filters = list;
  },
  [GET_FILTERS_LOADING](state, status) {
    state.filterLoading = status;
  },
  [CHANGE_LOADING_FEEDBACK](state, status) {
    state.feedbackLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
